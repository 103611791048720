<template v-if="preferenceUpdateData">
  <ul class="clebex-item-section date-time-format">
    <li class="clebex-item-section-item full-right-underline">
      <div class="clebex-item-content-wrapper">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label">{{ exampleDate }}</label>
        </div>
      </div>
    </li>
    <li class="clebex-item-section-item full-right-underline">
      <div class="clebex-item-content-wrapper">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label">{{
            displayLabelName("preferences", "set-preferences", "separator")
          }}</label>
        </div>
      </div>
      <div class="clebex-item-content-wrapper">
        <section class="clebex-pill-menu-tabs">
          <ul class="clebex-pill-tabs">
            <li class="clebex-pill-tabs-content visible">
              <div class="select-time-picker visible">
                <ul class="list-of-time-slots list-of-hours">
                  <li class="select-time-button-wrapper quad">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: separator === '/' }"
                      type="button"
                      @click="
                        separator = '/';
                        setData();
                      "
                    >
                      /
                    </button>
                  </li>
                  <li class="select-time-button-wrapper quad">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: separator === '-' }"
                      type="button"
                      @click="
                        separator = '-';
                        setData();
                      "
                    >
                      -
                    </button>
                  </li>
                  <li class="select-time-button-wrapper quad">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: separator === '.' }"
                      type="button"
                      @click="
                        separator = '.';
                        setData();
                      "
                    >
                      .
                    </button>
                  </li>
                  <li class="select-time-button-wrapper quad">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: separator === ' ' }"
                      type="button"
                      @click="
                        separator = ' ';
                        setData();
                      "
                    ></button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </li>
    <li class="clebex-item-section-item full-right-underline">
      <div class="clebex-item-content-wrapper">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label">{{
            displayLabelName("preferences", "set-preferences", "order")
          }}</label>
        </div>
      </div>
      <div class="clebex-item-content-wrapper">
        <section class="clebex-pill-menu-tabs">
          <ul class="clebex-pill-tabs">
            <li class="clebex-pill-tabs-content visible">
              <div class="select-time-picker visible">
                <ul class="list-of-time-slots list-of-hours">
                  <li class="select-time-button-wrapper triple">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: order === 1 }"
                      type="button"
                      @click="
                        order = 1;
                        setData();
                      "
                    >
                      mm-dd-yyyy
                    </button>
                  </li>
                  <li class="select-time-button-wrapper triple">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: order === 2 }"
                      type="button"
                      @click="
                        order = 2;
                        setData();
                      "
                    >
                      dd-mm-yyyy
                    </button>
                  </li>
                  <li class="select-time-button-wrapper triple">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: order === 3 }"
                      type="button"
                      @click="
                        order = 3;
                        setData();
                      "
                    >
                      yyyy-mm-dd
                    </button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </li>
    <li class="clebex-item-section-item full-right-underline">
      <div class="clebex-item-content-wrapper">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label">{{
            displayLabelName("preferences", "set-preferences", "month")
          }}</label>
        </div>
      </div>
      <div class="clebex-item-content-wrapper">
        <section class="clebex-pill-menu-tabs">
          <ul class="clebex-pill-tabs">
            <li class="clebex-pill-tabs-content visible">
              <div class="select-time-picker visible">
                <ul class="list-of-time-slots list-of-hours">
                  <li class="select-time-button-wrapper quad">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: month === 'M' }"
                      type="button"
                      @click="
                        month = 'M';
                        setData();
                      "
                    >
                      M
                    </button>
                  </li>
                  <li class="select-time-button-wrapper quad">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: month === 'MM' }"
                      type="button"
                      @click="
                        month = 'MM';
                        setData();
                      "
                    >
                      MM
                    </button>
                  </li>
                  <li class="select-time-button-wrapper quad">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: month === 'MMM' }"
                      type="button"
                      @click="
                        month = 'MMM';
                        setData();
                      "
                    >
                      MMM
                    </button>
                  </li>
                  <li class="select-time-button-wrapper quad">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: month === 'MMMM' }"
                      type="button"
                      @click="
                        month = 'MMMM';
                        setData();
                      "
                    >
                      MMMM
                    </button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </li>
    <li class="clebex-item-section-item full-right-underline">
      <div class="clebex-item-content-wrapper">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label">{{
            displayLabelName("preferences", "set-preferences", "day")
          }}</label>
        </div>
      </div>
      <div class="clebex-item-content-wrapper">
        <section class="clebex-pill-menu-tabs">
          <ul class="clebex-pill-tabs">
            <li class="clebex-pill-tabs-content visible">
              <div class="select-time-picker visible">
                <ul class="list-of-time-slots list-of-hours">
                  <li class="select-time-button-wrapper quad">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: day === 'D' }"
                      type="button"
                      @click="
                        day = 'D';
                        setData();
                      "
                    >
                      D
                    </button>
                  </li>
                  <li class="select-time-button-wrapper quad">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: day === 'DD' }"
                      type="button"
                      @click="
                        day = 'DD';
                        setData();
                      "
                    >
                      DD
                    </button>
                  </li>
                  <li class="select-time-button-wrapper quad">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: day === 'ddd' }"
                      type="button"
                      @click="
                        day = 'ddd';
                        setData();
                      "
                    >
                      ddd
                    </button>
                  </li>
                  <li class="select-time-button-wrapper quad">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: day === 'dddd' }"
                      type="button"
                      @click="
                        day = 'dddd';
                        setData();
                      "
                    >
                      dddd
                    </button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </li>
    <li class="clebex-item-section-item full-right-underline">
      <div class="clebex-item-content-wrapper">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label">{{
            displayLabelName("preferences", "set-preferences", "year")
          }}</label>
        </div>
      </div>
      <div class="clebex-item-content-wrapper">
        <section class="clebex-pill-menu-tabs">
          <ul class="clebex-pill-tabs">
            <li class="clebex-pill-tabs-content visible">
              <div class="select-time-picker visible">
                <ul class="list-of-time-slots list-of-hours">
                  <li class="select-time-button-wrapper double">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: year === 'YY' }"
                      type="button"
                      @click="
                        year = 'YY';
                        setData();
                      "
                    >
                      YY
                    </button>
                  </li>
                  <li class="select-time-button-wrapper double">
                    <button
                      data-skip
                      class="select-time-btn"
                      :class="{ selected: year === 'YYYY' }"
                      type="button"
                      @click="
                        year = 'YYYY';
                        setData();
                      "
                    >
                      YYYY
                    </button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dayjs from "dayjs";

export default {
  name: "DateFormat",
  data() {
    return {
      initialValue: null,
      order: null,
      separator: null,
      separators: ["/", "-", ".", " "],
      day: null,
      month: null,
      year: null,
      exampleDate: null,
      newValue: null
    };
  },
  mounted() {
    this.setInitialData();
  },
  computed: {
    ...mapState("companyPreferences", ["preferences", "preferenceUpdateData"])
  },
  props: {
    preferenceId: {
      type: Number,
      required: true
    }
  },
  methods: {
    ...mapActions("companyPreferences", ["setPreferenceUpdateData"]),
    setInitialData() {
      this.initialValue = this.preferenceUpdateData.values[0].value;
      switch (this.initialValue.charAt(0)) {
        case "M":
          this.order = 1;
          break;
        case "d":
        case "D":
          this.order = 2;
          break;
        case "Y":
          this.order = 3;
          break;

        default:
          this.order = 1;
          break;
      }
      this.separators.forEach(separator => {
        if (this.initialValue.includes(separator)) {
          this.separator = separator;
        }
      });
      const initialValueArray = this.initialValue.split(`${this.separator}`);
      initialValueArray.forEach(element => {
        switch (element.charAt(0)) {
          case "M":
            this.month = element;
            break;
          case "d":
          case "D":
            this.day = element;
            break;
          case "Y":
            this.year = element;
            break;

          default:
            break;
        }
      });
      this.setNewValue();
    },
    setData() {
      this.setNewValue();
      const newPreferenceUpdateData = {
        values: [
          {
            value: this.newValue
          }
        ]
      };
      if (this.preferenceUpdateData.values[0].id) {
        newPreferenceUpdateData.values[0].id = this.preferenceUpdateData.values[0].id;
      }
      this.setPreferenceUpdateData(newPreferenceUpdateData);
    },
    setNewValue() {
      switch (this.order) {
        case 1:
          this.newValue = `${this.month}${this.separator}${this.day}${this.separator}${this.year}`;
          break;
        case 2:
          this.newValue = `${this.day}${this.separator}${this.month}${this.separator}${this.year}`;
          break;
        case 3:
          this.newValue = `${this.year}${this.separator}${this.month}${this.separator}${this.day}`;
          break;

        default:
          break;
      }
      this.exampleDate = dayjs().format(this.newValue);
    }
  }
};
</script>
